
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  props: {
    day: {
      type: String
    }
  },
  setup(props) {
    const week_orders = ref({});
    const total = ref({});

    const processing = ref(false);

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const s = props.day ? new Date(props.day) : friday();
    const start = ref(s);
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function prevDay(d: any) {
      return moment(d)
        .subtract(1, "days")
        .format("dddd, MMMM Do, YYYY");
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function getYear(dte: any) {
      return format(dte, "Y");
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function sum(items: any[], key: string | number) {
      const summation = items.reduce(function(a: any, b: { [x: string]: any }) {
        return a + b[key];
      }, 0);
      return addCommas(summation);
    }

    function fetchWeekOrders() {
      processing.value = true;
      http
        .get(
          `/api/week-orders-by-customer/sorted?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`
        )
        .then(res => {
          week_orders.value = res.data.data;
          total.value = res.data.total;
          processing.value = false;
        });
    }
    function isEmpty(obj: any) {
      return Object.keys(obj).length === 0;
    }

    onMounted(() => {
      fetchWeekOrders();
    });

    watch(
      () => start.value,
      (v: any) => {
        fetchWeekOrders();
      }
    );

    return {
      processing,
      fD,
      getDates,
      start,
      end,
      getYear,
      week_orders,
      sum,
      total,
      addCommas,
      isEmpty,
      prevDay
    };
  }
});
