<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link to="/orders" class="back-to">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Back To Orders Input</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h3 class="ng-block-title fw-normal">
            Week {{GetWeek(start)}} - {{ getYear(start) }} Orders
          </h3>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  Week
                  <span class="d-none d-sm-inline-block">
                    {{GetWeek(start)}}
                  </span>
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Start Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>
                      <div class="col-md-5 text-center">
                        <h5>Starting on</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <h5>Ending on</h5>
                        <p>
                          {{ fD(end) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Week </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div v-else>
        <div class="card-content">
          <table class="table table-tranx table-bordered">
            <tfoot class="tb-tnx-head d-print-none">
              <tr class="tb-tnx-item">
                <th class="tb-tnx-info align-middle">
                  <h6 class="text-left">Daily Summaries</h6>
                </th>
                <th
                  v-for="(d, i) in getDates(start)"
                  :key="i"
                  class="text-center align-middle"
                >
                  <router-link
                    :to="`/reports/day/${d}`"
                    class="btn btn-outline-light btn-sm"
                  >
                    {{ fD(new Date(d), "do, EEEE") }}
                  </router-link>
                </th>
                <th></th>
              </tr>
            </tfoot>
          </table>
          <div class="row" v-if="!isEmpty(week_orders)">
            <table class="table table-tranx table-bordered col-4">
              <tfoot class="tb-tnx-head">
                <tr class="tb-tnx-item">
                  <th class="tb-tnx-info align-middle">
                    <h6 class="text-left">Total Tonnage</h6>
                  </th>
                  <th class="text-center align-middle">
                    {{ addCommas(total.tonnage) }} Kg
                  </th>
                </tr>
                <tr class="tb-tnx-item">
                  <th class="tb-tnx-info align-middle">
                    <h6 class="text-left">Total Timing</h6>
                  </th>
                  <th class="text-center align-middle">
                    {{ total.timing }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div
          class="col-12 mb-3"
          v-for="(d, i) in getDates(start, end)"
          :key="i"
        >
          <div
            style="font-size: 11px;font-weight: 800;letter-spacing: 0.12em;background: #00964c;"
          >
            <div class="align-middle" style="padding: 20px 12px">
              <h6 class="text-left text-white">
                {{ fD(new Date(d), "E, do MMMM yyyy") }}
              </h6>
            </div>
          </div>
          <div
            class="card mt-0"
            v-for="(details, key) in week_orders[d]"
            :key="key"
          >
            <div class="card-content" v-if="details.orders.length > 0">
              <div class="my-1 border-bottom border-4">
                <table class="table table-tranx table-bordered">
                  <tr class="tb-tnx-item compact-table dark-table-row">
                    <td colspan="6">Customer</td>
                    <td colspan="7">{{ details.customer?.name }}</td>
                  </tr>
                  <tr class="tb-tnx-item compact-table">
                    <td colspan="3">Departure</td>
                    <td colspan="3">{{ fD(new Date(d), "PPPP") }}</td>
                    <td colspan="3">Pack Date</td>
                    <td colspan="4">{{ prevDay(d) }}</td>
                  </tr>
                  <tr
                    class="tb-tnx-item compact-table"
                    v-if="details.freight_agent"
                  >
                    <td colspan="6">Agent</td>
                    <td colspan="7">{{ details.freight_agent?.name }}</td>
                  </tr>
                  <tr class="tb-tnx-item compact-table dark-table-row">
                    <td class="tb-tnx-info font-weight-bold">
                      Boxes Ordered
                    </td>
                    <td class="tb-tnx-info">Product</td>
                    <td class="tb-tnx-info">Departure Date</td>
                    <td class="tb-tnx-info">Count</td>
                    <td class="tb-tnx-info">Packaging</td>
                    <td class="tb-tnx-info">Label</td>
                    <td class="tb-tnx-info">Box Type</td>
                    <td class="tb-tnx-info">Box Weight</td>
                    <td class="tb-tnx-info">Total Weight</td>
                    <td class="tb-tnx-info">% Fill</td>
                    <td class="tb-tnx-info">Target</td>
                    <td class="tb-tnx-info">Timing</td>
                    <td class="tb-tnx-info" colspan="2">Unit Cost</td>
                  </tr>
                  <tbody>
                    <tr
                      class="tb-tnx-item compact-table"
                      v-for="(order, key) in details.orders"
                      :key="key"
                    >
                      <td class="tb-tnx-info align-middle font-weight-bold">
                        {{ order.quantity }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.variety_name }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.date }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.variety_count }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.packaging?.name }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.label?.name }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.box?.name }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.unit_weight }} Kg
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ addCommas(order.total_weight) }} Kg
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.percentage_fill }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.target }}
                      </td>
                      <td class="tb-tnx-info align-middle">
                        {{ order.timing }}
                      </td>
                      <td class="tb-tnx-info align-middle" colspan="2">
                        {{ order.currency }} {{ addCommas(order.unit_cost) }}
                      </td>
                      <!--                      <td class="tb-tnx-info align-middle">-->
                      <!--                        {{ order.currency }} {{ addCommas(order.total_cost) }}-->
                      <!--                      </td>-->
                    </tr>
                  </tbody>
                  <tfoot class="tb-tnx-head">
                    <tr class="tb-tnx-item">
                      <th style="font-weight: bolder; vertical-align: middle">
                        {{ sum(details.orders, "quantity") }}
                      </th>
                      <th class="tb-tnx-info" colspan="6"></th>
                      <th
                        colspan="2"
                        style="text-align: center;vertical-align: middle"
                      >
                        {{ sum(details.orders, "total_weight") }} Kg
                      </th>
                      <th
                        class="tb-tnx-info"
                        style="text-align: center;vertical-align: middle"
                      >
                        {{ sum(details.orders, "percentage_fill") }}
                      </th>
                      <th class="tb-tnx-info"></th>
                      <th
                        class="tb-tnx-info"
                        style="text-align: center;vertical-align: middle"
                      >
                        {{ sum(details.orders, "timing") }}
                      </th>
                      <th colspan="2" style="text-align: center">
                        {{ details.customer.contact?.currency_symbol }}
                        {{ sum(details.orders, "total_cost") }}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="isEmpty(week_orders)"
          class="alert alert-warning text-center"
        >
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          There are no orders for this week
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import moment from "moment";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: {NgongVegBanner},
  props: {
    day: {
      type: String
    }
  },
  setup(props) {
    const week_orders = ref({});
    const total = ref({});

    const processing = ref(false);

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const s = props.day ? new Date(props.day) : friday();
    const start = ref(s);
    const end = computed(
      () => new Date(new Date(start.value).getTime() + 6 * 24 * 60 * 60 * 1000)
    );

    function prevDay(d: any) {
      return moment(d)
        .subtract(1, "days")
        .format("dddd, MMMM Do, YYYY");
    }

    function getDates(s: any) {
      const e = new Date(new Date(s).getTime() + 6 * 24 * 60 * 60 * 1000);

      const dates = [];
      let currentDate = moment(s);
      const eDate = moment(e);
      while (currentDate <= eDate) {
        dates.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      return dates;
    }

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    function getYear(dte: any) {
      return format(dte, "Y");
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    function sum(items: any[], key: string | number) {
      const summation = items.reduce(function(a: any, b: { [x: string]: any }) {
        return a + b[key];
      }, 0);
      return addCommas(summation);
    }

    function fetchWeekOrders() {
      processing.value = true;
      http
        .get(
          `/api/week-orders-by-customer/sorted?start=${fD(
            start.value,
            "yyyy-MM-dd"
          )}&end=${fD(end.value, "yyyy-MM-dd")}`
        )
        .then(res => {
          week_orders.value = res.data.data;
          total.value = res.data.total;
          processing.value = false;
        });
    }
    function isEmpty(obj: any) {
      return Object.keys(obj).length === 0;
    }

    onMounted(() => {
      fetchWeekOrders();
    });

    watch(
      () => start.value,
      (v: any) => {
        fetchWeekOrders();
      }
    );

    return {
      processing,
      fD,
      getDates,
      start,
      end,
      getYear,
      week_orders,
      sum,
      total,
      addCommas,
      isEmpty,
      prevDay
    };
  }
});
</script>
